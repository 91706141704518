import React, { MouseEvent, useEffect, useState } from "react";
import mqtt, { MqttClient } from "mqtt";
import Layout from "layouts/EmptyLayout";
import Loading from "components/Common/Loading";

const MQTT_URL = `wss://broker.emqx.io:8084/mqtt`;
const MQTT_OPTIONS = {
  clientId: 'emqx_react_' + Math.random().toString(16).substring(2, 8),
  username: 'emqx',
  password: 'public',
  clean: true,
  //reconnectPeriod: 1000, // ms
  connectTimeout: 30 * 1000, // ms
};

const BGM_PPLAYERS = [
  {
    "id": "1",
    "name": "Living Player",
  },
  {
    "id": "cTFeAPgdykkr",
    "name": "Working Room Player",
  }
];

const PLAYER_OPTIONS = {
  "idPrefix": "bgm-player-",
  "topicPrefix": "thangiswho/",
  "actions": [
    {
      "name": "Play/pause ♫",
      "action": "play"
    },
    {
      "name": "Next song ›",
      "action": "cmd-mode-AT+CC"
    },
    {
      "name": "Next folder ▶︎",
      "action": "cmd-mode-AT+AA06"
    },
    {
      "name": "Volume down -",
      "action": "cmd-mode-AT+CF"
    },
    {
      "name": "Volume up +",
      "action": "cmd-mode-AT+CE"
    },
    {
      "name": "Stop ◻︎",
      "action": "stop"
    },
    {
      "name": "Reset volume",
      "action": "cmd-mode-AT+CA03"
    },
    {
      "name": "Bluetooth Mode",
      "action": "ble-mode"
    },
    {
      "name": "TF Card Mode",
      "action": "tf-mode"
    },
    {
      "name": "Sound Card Mode",
      "action": "cmd-mode-AT+CM07"
    },
  ]
};

export default function Music() {
  const [mqttClient, setMqttClient] = useState<MqttClient|null>(null);
  const [publishCallback, setPublishCallback] = useState<Function|null>(null);
  const [connectStatus, setStatus] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const mqttPublish = (playerId: string, action: string) => {
    setPublishCallback(() => {
      console.log('publishing [%s]...', action);
      mqttClient?.publish(PLAYER_OPTIONS.topicPrefix + PLAYER_OPTIONS.idPrefix + playerId, action, {}, error => {
        if (error) {
          console.log('Publish error: ', error);
        }
        setLoading(false);
        setPublishCallback(null);
      });
    });

    setLoading(true);
    if (!mqttClient) {
      mqttConnect();
    } else if ( mqttClient.connected){
      if (publishCallback) publishCallback();
    } else {
      mqttClient.reconnect();
    }
  };

  const mqttConnect = () => {
    let client = mqtt.connect(MQTT_URL, MQTT_OPTIONS);
    setStatus('connecting');
    setLoading(true);
    client.on('connect', () => {
      console.log('connected');
      setMqttClient(client);
      setStatus('connected');
      if (publishCallback) {
        publishCallback();
      } else {
        setLoading(false);
      }
    });
    client.on('reconnect', () => {
      console.log('--->reconnect');
      setStatus('reconnecting');
    });
    client.on('error', (err) => {
      console.log(`Connection error: ${err}`);
      setStatus(err.message);
      setLoading(false);
    });
    client.on('offline', () => {
      console.log('--->offline');
      setStatus('offline');
      setLoading(false);
    });
    client.on('end', () => {
      console.log('--->end');
      setStatus('offline');
      setLoading(false);
    });
  }

  useEffect(() => {
    console.log("initialized!")
    if (!mqttClient) {
      mqttConnect();
    } else if (! mqttClient.connected) {
      mqttClient.reconnect();
    }
    return () => {
      mqttClient?.end(true);
    };
  }, [mqttClient]);

  return (
    <>
      <Layout title="BGM Player">
        { loading && <Loading className="fixed h-32 w-full top-0 mx-auto">{connectStatus}</Loading>}
        <h1 className="w-full mt-8 p-4 h-full text-xl">BGM Players <span className="text-xs">({connectStatus})</span></h1>

        {BGM_PPLAYERS.map((player, pi) => (
          <div className="w-full mt-8 px-4 h-full" key={pi}>
            <h2 className="w-full p-4 text-lg">{player.name}</h2>
            <div className="w-full flex flex-wrap">
              {PLAYER_OPTIONS.actions.map((cmd, i) => (
                <div className="flex flex-col w-6/12 md:w-3/12 lg:w-2/12 xl:w-1/12 p-3" key={i}>
                  <a href="#" onClick={(e) => {mqttPublish(player.id, cmd.action); e.preventDefault()}} className="w-full align-middle object-center rounded-lg p-4 bg-blue-700 hover:bg-orange-600 text-gray-300 hover:text-gray-300">
                    <div className="w-full mx-auto">{cmd.name}</div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        ))}
      </Layout>
    </>
  );
}
